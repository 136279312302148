import * as React from "react";
import Layout from "../components/layout/layout";
import NotFound from "../sections/notfound/NotFound";

// markup
const NotFoundPage = () => {
  return (
    <Layout pageTitle="Page not found - Lossless">
      <NotFound></NotFound>
    </Layout>
  );
};

export default NotFoundPage;
