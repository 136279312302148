import React from "react";
import * as styles from "./NotFound.module.scss";
import Container from "../../components/container/container";
import CustomLink from "../../components/customLink/CustomLink";

const NotFound = () => {
  return (
    <section className={`${styles.notFound}`}>
      <Container>
        <h1>404</h1>
        <p>Sorry, we couldn’t find what you were looking for.</p>
        <div className={styles.notFoundButton}>
          <CustomLink
            url="/"
            internal
            type="blue"
            size="big"
            text="Go home"
          ></CustomLink>
        </div>
      </Container>
    </section>
  );
};

export default NotFound;
